import React from "react";
import shortid from  "shortid";
const EletiveSection2 = (props) => {
    return(
        <section className="section-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-22">
                        <div className="title-block">
                            <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-21">
                                    <h1 className="h1 section-title normal-spacing">{props.mainTitle}</h1>
                                    <p className="section-description">{props.mainDescription}</p>
                                </div>
                                <div className="col-lg-2"></div>
                            </div>
                        </div>
                        {props.imageslist?.map((object, i) => {
                            return (
                                <div className="image-block" key={shortid.generate()}>
                                    <img
                                        src={object.image1x?.sourceUrl}
                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                        width={object.image1x?.width}
                                        alt={object.image1x?.altText}
                                    />
                                </div>     
                            )
                        })}
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </section>
    )
}

export default EletiveSection2;